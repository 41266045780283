<template>
    <table class="table dataTable table-striped no-footer" role="grid">
        <thead>
            <tr role="row">
                <th class="sorting">Constituency</th>
                <th class="sorting">Branch Code</th>
                <th class="sorting">Branch</th>
                <th class="sorting">Name</th>
                <th class="sorting">Phone</th>
                <th class="sorting">IMEI</th>
                <th class="sorting">Created At</th>
                <th class="sorting">Updated At</th>
                <th></th>
            </tr>
        </thead>

        <tbody>
            <tr role="row" v-for="(dataCollector, index) in dataCollectors" :key="index">
                <td>{{ dataCollector.branch.electoral_area.constituency.name }}</td>
                <td>{{ dataCollector.branch.code ? dataCollector.branch.code : 'N/A' }}</td>
                <td>{{ dataCollector.branch.name }}</td>
                <td>{{ dataCollector.user.name }} </td>
                <td>{{ dataCollector.phone }} </td>
                <td>{{ dataCollector.imei }} </td>
                <td>{{ dataCollector.created_at | formattedDateTime }}</td>
                <td>{{ dataCollector.updated_at | formattedDateTime }}</td>
                <td>
                    <button class="btn btn-warning btn-action mr-3" @click="$emit('edit', dataCollector)" data-toggle="popover" data-content="Edit Data Collector">
                        <i class="mdi mdi-pencil"></i>
                    </button>

                    <button class="btn btn-danger btn-action" @click="$emit('delete', dataCollector.id)" data-toggle="popover" data-content="Delete Data Collector">
                        <i class="mdi mdi-trash-can"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    export default {
        props: {
            dataCollectors: {
                type: Array,
                default: []
            }
        }
    }
</script>