<template>
    <div class="row">
        <div class="col">
            <div class="card grid-margin">
                <div class="card-body">
                    <h1 class="card-title">
                        <i class="mdi mdi-account-details"></i>
                        Data Collectors
                    </h1>

                    <p class="card-description">The people who collect and send data during elections</p>

                    <div class="row">
                        <div class="col-12">
                            <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                                <div class="row">
                                    <div class="col-xs-12 col-3">
                                        <button type="button" class="btn btn-primary" @click="addDataCollector">
                                            <i class="mdi mdi-plus"></i>
                                            Add Data Collector
                                        </button>
                                    </div>

                                    <div class="col-xs-12 col-4">
                                        <div class="row">
                                            <div class="col-xs-12 col-6">
                                                <div class="form-group">
                                                    <div class="select2-wrapper">
                                                        <select
                                                            id="constituency"
                                                            ref="constituency"
                                                            class="select-2 form-control"
                                                            data-placeholder="Select A Constituency">
                                                                <option></option>
                                                                <option disabled>Select A Constituency</option>

                                                                <option v-for="(constituency, index) in constituencies"
                                                                    :value="constituency.id"
                                                                    :key="index">{{ constituency.name }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-xs-12 col-6">
                                                <div class="form-group">
                                                    <div class="select2-wrapper">
                                                        <select
                                                            id="branch"
                                                            ref="branch"
                                                            class="select-2 form-control"
                                                            data-placeholder="Select A Branch">
                                                                <option></option>
                                                                <option disabled>Select A Constituency</option>

                                                                <option v-for="(branch, index) in branches"
                                                                    :value="branch.id"
                                                                    :key="index">
                                                                    {{ branch.name }}
                                                                </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xs-12 col-5">
                                        <toolbar
                                            @show="val => params.per_page = val"
                                            @search="val => params.keyword = val" />
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <template v-if="dataCollectors.length">
                                            <div class="table-responsive">
                                                <data-collectors-table
                                                    @edit="editDataCollector"
                                                    :data-collectors="dataCollectors"
                                                    @delete="deleteDataCollector" />
                                            </div>

                                            <app-pagination
                                                v-if="pageDetails && pageDetails.total"
                                                :pageDetails="pageDetails"
                                                @navigate="getDataCollectors($event)" />
                                        </template>

                                        <template v-else>
                                            <p class="lead mt-5 mb-5 text-center">There are no data collectors in the system.</p>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

		<modal
			:show="showModal"
            :data-collector="modalDataCollector"
            @save-data-collector="saveDataCollector"
			@update:show="val => showModal = val" />
    </div>
</template>

<script>
    import Modal from './Modal';
    import { mapActions, mapGetters } from 'vuex';
    import DataCollectorsTable from './Table';

    export default {
        components: {
            Modal,
            DataCollectorsTable,
        },

        data() {
            return {
                showModal: false,
                modalDataCollector: {},
                params: {
                    paginate: true,
                    per_page: null,
                    keyword: null,
                    constituency_id: null,
                    branch_id: null
                }
            };
        },

        computed: {
            ...mapGetters({
                branches: 'Branches/getAll',
                dataCollectors: 'DataCollectors/getAll',
                constituencies: 'Constituencies/getAll',
                pageDetails: 'DataCollectors/getPageDetails',
            })
        },

        watch: {
            params: {
                deep: true,
                handler() {
                    this.getDataCollectors();
                }
            },

            showModal(value) {
                if (!value) {
                   this.modalDataCollector = {};
                }
            }
        },

		methods: {
			...mapActions({
                load: 'DataCollectors/all',
                store: 'DataCollectors/store',
                update: 'DataCollectors/update',
                delete: 'DataCollectors/delete',
                loadBranches: 'Branches/all',
                loadConstituencies: 'Constituencies/all',
            }),

            /**
             * Open the add data collector modal.
             *
             * @return {Undefined}
             */
            addDataCollector() {
                this.showModal = true;
            },

            /**
             * Open the edit data collector modal.
             *
             * @param {Object} dataCollector
             * @return {Undefined}
             */
            editDataCollector(dataCollector) {
                this.showModal = true;
                this.modalDataCollector = { ...dataCollector };
            },

            /**
             * Get all data collectors from the server.
             *
             * @param {Object} query
             * @return {Undefined}
             */
            getDataCollectors(query = {}) {
                for (let param of Object.keys(this.params)) {
                    if (this.params[param]) {
                        query[param] = this.params[param];
                    }
                }

                this.load(query)
                    .catch(errors => this.notify(this.buildErrors(errors), 'error'));
            },

            /**
             * Save the data collector in the backend.
             *
             * @param {Object} dataCollector
             * @return {Undefined}
             */
            saveDataCollector(dataCollector) {
                let keyword = 'added';
                let savingMethod = this.store;
                let payload = { data: dataCollector };

                if (dataCollector.id !== 'undefined' && dataCollector.id) {
                    keyword = 'updated';
                    payload.id = dataCollector.id;
                    savingMethod = this.update;
                }

                savingMethod(payload)
                    .then(response => {
                        this.notify(`The data collector was ${keyword} successfully.`);
                        this.getDataCollectors();
                        this.showModal = false;
                        this.modalDataCollector = {};
                    })
                    .catch(errors => this.notify(this.buildErrors(errors), 'error'));
            },

			/**
			 * Delete the specified data collector.
             * Request for a deletion confirmation.
			 *
			 * @param  {Number} id
			 * @return {Undefined}
			 */
			deleteDataCollector(id) {
				Swal({
					title: "Are you sure?",
					text: "Once deleted, you will not be able to recover this data collector and its data!",
					type: "warning",
                    showCancelButton: true,
                    confirmButtonText: 'Yes - Delete it.',
                    confirmButtonClass: 'bg-danger',
                    cancelButtonText: 'No - Cancel Delete'
				})
				.then(response => {
					if (response.value) {
						this.delete(id)
							.then(() => {
								this.notify('The data collector was deleted successfully.');
								this.getDataCollectors();
							})
							.catch(errors => this.notify(this.buildErrors(errors), 'error'))
					}
				});
            },

            /**
             * Load constituencies
             *
             * @param {Object} query
             * @return {Undefined}
             */
            getConstituencies(query = {}) {
                this.loadConstituencies(query)
                    .catch(errors => this.notify(this.buildErrors(errors), 'error'));
            },

            /**
             * Load branches
             *
             * @param {Object} event
             * @return {Undefined}
             */
            getBranches(event) {
                const constituency_id = event.target.value;

                if (!constituency_id) {
                    return false;
                }

                this.loadBranches({
                    constituency_id,
                    hide_unknown: true,
                })
                    .catch(errors => this.notify(this.buildErrors(errors), 'error'));
            },

            /**
             * Set branch value when the select changes.
             *
             * @param {Object} event
             * @return {Undefined}
             */
            setBranch(event) {
                this.params.branch_id = event.target.value;
            },

            /**
             * Set user value when the select changes.
             *
             * @param {Object} event
             * @return {Undefined}
             */
            setConstituency(event) {
                this.params.constituency_id = event.target.value;
                this.getBranches(event);
            },

            /**
             * Register event listeners for select 2.
             *
             * @return {Undefined}
             */
            registerEventListeners() {
                $(this.$refs.branch).on('change', this.setBranch);
                $(this.$refs.constituency).on('change', this.setConstituency);
            }
        },

        mounted() {
            this.getDataCollectors();
            this.loadConstituencies();
            this.registerEventListeners();
        }
    }
</script>

<style lang="scss" scoped>
</style>


