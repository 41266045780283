<template>
    <modal
        :force="true"
        :show="show"
        :title="title"
        :ok-text="title"
        :close-when-cancel="true"
        @cancel="$emit('update:show', false)"
        @ok="$emit('save-data-collector', payload)"
        @update:show="$emit('update:show', $event)">
			<div class="row">
				<div class="col-12">
                    <div class="form-group">
                        <label for="constituency">Constituency</label>

                        <div class="select2-wrapper">
                            <select
                                id="constituency"
                                ref="constituency"
                                class="select-2 form-control"
                                data-placeholder="Select A Constituency">
                                    <option></option>

                                    <option v-for="(constituency, index) in constituencies"
                                        :value="constituency.id"
                                        :key="index">{{ constituency.name }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="branch">Branches</label>

                        <div class="select2-wrapper">
                            <select
                                id="branch"
                                ref="branch"
                                class="select-2 form-control"
                                data-placeholder="Select A Branch">
                                <option></option>

                                <template v-for="(branch, index) in branches">
                                    <optgroup :key="index" :label="branch.code">
                                        <option :value="branch.id">
                                            {{ branch.name }}
                                        </option>
                                    </optgroup>
                                </template>
                            </select>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="user">User</label>

                        <div class="select2-wrapper">
                            <select
                                id="user"
                                ref="user"
                                class="form-control"
                                data-placeholder="Choose A User">
                            </select>
                        </div>
                    </div>
				</div>
			</div>

			<div class="row">
				<div class="col-xs-12 col-6">
                    <div class="form-group" :class="imeiClass">
                        <label for="imei">IMEI</label>
                        <input id="imei"
                            type="text"
                            name="imei"
                            v-model="payload.imei"
                            class="form-control"
                            v-validate="rules.imei"
                            placeholder="IMEI"/>

                        <error-label :message="errors.first('imei')" />
                    </div>
				</div>

				<div class="col-xs-12 col-6">
                    <div class="form-group" :class="phoneClass">
                        <label for="phone">Phone</label>
                        <input type="text"
                            name="phone"
                            v-model="payload.phone"
                            class="form-control"
                            v-validate="rules.phone"
                            placeholder="Phone Number"/>

                        <error-label :message="errors.first('phone')" />
                    </div>
				</div>
            </div>
    </modal>
</template>

<script>
    import Config from '@/../config';
    import { mapGetters, mapActions } from 'vuex';

	export default {
		props: {
            show: {
                type: Boolean,
                default: false
            },

            dataCollector: {
                type: Object,
                default: {}
            }
        },

		data() {
			return {
                branches: [],
                selectedUser: null,
                selectedBranch: null,

                payload: {
                    imei: '',
                    phone: '',
                    user_id: '',
                    branch_id: ''
                },

                rules: {
                    imei: 'required|digits:15',
                    phone: 'required|digits:10',
                }
			};
        },

        computed: {
            ...mapGetters({
                authUser: 'Auth/getUser',
                constituencies: 'Constituencies/getAll',
            }),

            /**
             * Check if the current operation is an update.
             *
             * @return {Boolean}
             */
            isUpdate() {
                return Object.keys(this.dataCollector).length > 0;
            },

            /**
             * Compute the has danger class for the imei field.
             *
             * @return {Object}
             */
            imeiClass() {
                return { 'has-danger': this.errors.has('imei') };
            },

            /**
             * Compute the has danger class for the phone field.
             *
             * @return {Object}
             */
            phoneClass() {
                return { 'has-danger': this.errors.has('phone') };
            },

            /**
             * Title for the modal depending on the current operation.
             *
             * @return {String}
             */
            title() {
                return this.isUpdate ? 'Edit Data Collector' : 'Add Data Collector';
            }
        },

		watch: {
            /**
             * Toggle the id property depending on the save operation. Update or Save.
             *
             * @param {Boolean} value
             * @return {Undefined}
             */
			show(value) {
                this.resetModal();

                if (this.isUpdate) {
                    if (value) {
                        this.addSelectedUserOption();
                        this.addSelectedBranchOption();
                    }

                    this.getDataCollectorDetails();
                    this.$set(this.payload, 'id', this.dataCollector.id);
                } else {
                    this.$delete(this.payload, 'id');
                }

                if (!value) {
                    this.removeSelectedUserOption();
                    this.removeSelectedBranchOption();
                }
			}
		},

		methods: {
            ...mapActions({
                loadConstituencies: 'Constituencies/all',
                loadBranches: 'Branches/all'
            }),

            /**
             * Load constituencies
             *
             * @param {Object} query
             * @return {Undefined}
             */
            getConstituencies(query = {}) {
                this.loadConstituencies(query)
                    .catch(errors => this.notify(this.buildErrors(errors), 'error'));
            },

            /**
             * Load branches
             *
             * @param {Object} event
             * @return {Undefined}
             */
            getBranches(event) {
                const constituency_id = event.target.value;

                if (!constituency_id) {
                    return false;
                }

                this.loadBranches({
                    constituency_id,
                    hide_unknown: true,
                    only_unassigned: true
                })
                .then(response => this.branches = response)
                .catch(errors => this.notify(this.buildErrors(errors), 'error'));
            },

            /**
             * Set branch value when the select changes.
             *
             * @param {Object} event
             * @return {Undefined}
             */
            setBranch(event) {
                this.payload.branch_id = event.target.value;
            },

            /**
             * Set user value when the select changes.
             *
             * @param {Object} event
             * @return {Undefined}
             */
            setUser(event) {
                this.payload.user_id = event.target.value;
            },

            /**
             * Get the details of the data collector to be updated.
             *
             * @return {Undefined}
             */
            getDataCollectorDetails() {
                for (let key of Object.keys(this.payload)) {
                    if (typeof this.dataCollector[key] !== 'undefined') {
                        this.payload[key] = this.dataCollector[key];
                    }
                }

                this.selectedUser = this.payload.user_id;
                this.selectedBranch = this.payload.branch_id;

                $(this.$refs.user).val(this.payload.user_id).trigger('change');
                $(this.$refs.branch).val(this.payload.branch_id).trigger('change');
                $(this.$refs.constituency).val(this.dataCollector.branch.constituency_id).trigger('change');
            },

            /**
             * Reset the modal.
             *
             * @return {Undefined}
             */
            resetModal() {
                for (let key of Object.keys(this.payload)) {
                    this.payload[key] = '';
                }

                $(this.$refs.user).val('').trigger('change');
                $(this.$refs.branch).val('').trigger('change');
                $(this.$refs.constituency).val('').trigger('change');

                this.$nextTick(() => this.errors.clear());
            },

            /**
             * Configure user selection to use ajax to retrieve searched words.
             *
             * @return {Undefined}
             */
            initUserSelection() {
                $(this.$refs.user).select2({
                    ajax: {
                        delay: 250,
                        dataType: 'json',
                        minimumInputLength: 2,
                        url: Config[process.env.NODE_ENV].selectUserURL,

                        data(params) {
                            return {
                                paginate: true,
                                keyword: params.term,
                                page: params.page || 1,
                                data_collectors: 'only_unassigned',
                            }
                        },

                        headers: {
                            Authorization: `Bearer ${this.authUser.api_token}`
                        },

                        processResults: this.processResults
                    }
                });
            },

            /**
             * Add selected branch option.
             *
             * @return {Undefined}
             */
            addSelectedBranchOption() {
                const option = $(this.$refs.branch).find(`option[value=${this.dataCollector.branch.id}]`);

                if (option.length) {
                    return false;
                }

                $(this.$refs.branch).prepend(new Option(
                    this.dataCollector.branch.name,
                    this.dataCollector.branch.id,
                    true,
                    true
                ))
                .trigger('change');
            },

            /**
             * Remove selected branch option.
             *
             * @return {Undefined}
             */
            removeSelectedBranchOption() {
                const option = $(this.$refs.branch).find(`option[value=${this.selectedBranch}]`);

                if (option.length) {
                    option.remove();
                    $(this.$refs.branch).trigger('change');
                }
            },

            /**
             * Add selected user option.
             *
             * @return {Undefined}
             */
            addSelectedUserOption() {
                const option = $(this.$refs.user).find(`option[value=${this.dataCollector.user.id}]`);

                if (option.length) {
                    return false;
                }

                $(this.$refs.user).prepend(new Option(
                    this.dataCollector.user.name,
                    this.dataCollector.user.id,
                    true,
                    true
                ))
                .trigger('change');
            },

            /**
             * Remove selected user option.
             *
             * @return {Undefined}
             */
            removeSelectedUserOption() {
                const option = $(this.$refs.user).find(`option[value=${this.selectedUser}]`);

                if (option.length) {
                    option.remove();
                    $(this.$refs.user).trigger('change');
                }
            },

            /**
             * Process users list ajax response.
             *
             * @param {Object} response
             * @return {Object}
             */
            processResults(response) {
                const results = response.data.map(user => {
                    return {
                        id: user.id,
                        text: user.name
                    }
                });

                // Add the selected user to the list
                if (this.isUpdate) {
                    results.unshift({
                        selected: true,
                        id: this.dataCollector.user.id,
                        text: this.dataCollector.user.name,
                    });
                }


                return {
                    results,
                    pagination: {
                        more: response.current_page < response.last_page
                    }
                };
            },

            /**
             * Register event listeners for select 2.
             *
             * @return {Undefined}
             */
            registerEventListeners() {
                $(this.$refs.user).on('change', this.setUser);
                $(this.$refs.branch).on('change', this.setBranch);
                $(this.$refs.constituency).on('change', this.getBranches);
            }
        },

        mounted() {
            this.initUserSelection();
            this.loadConstituencies();
            this.registerEventListeners();
        }
	}
</script>

<style scoped>
</style>
